function App() {
    
    
    this.init = function() {

        

    };

    //MENU XS
    if ($('.button-menu').length) {
        $('.button-menu').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $('html').toggleClass('body-menu-active');
        });
    }

    //SLIDER HEADER
    if ($('.sliderh--js').length > 0) {
        $('.sliderh--js').slick({
            dots: true,
            infinite: true,
            speed: 500,
            fade: false,
            arrows: false,
            cssEase: 'linear',
            dotsClass: 'sliderh__dots',
            autoplay: true,
            autoplaySpeed: 2000
        });
    }


    //SLIDER ASIGURARI XS
    setTimeout(function() {
    
        if ($('.sliderpf--xs-js').length > 0) {
            if($(window).width() <= 767) {
                $('.sliderpf--xs-js').slick({
                    dots: true,
                    infinite: true,
                    speed: 500,
                    fade: false,
                    arrows: false,
                    cssEase: 'linear',
                    dotsClass: 'sliderh__dots',
                });    
            }
        }
        if ($('.sliderpj--xs-js').length > 0) {
            if($(window).width() <= 767) {
                $('.sliderpj--xs-js').slick({
                    dots: true,
                    infinite: true,
                    speed: 500,
                    fade: false,
                    arrows: false,
                    cssEase: 'linear',
                    dotsClass: 'sliderh__dots',
                });    
            }
        }
        if ($('.sliderdd--xs-js').length > 0) {
            if($(window).width() <= 767) {
                $('.sliderdd--xs-js').slick({
                    dots: true,
                    infinite: true,
                    speed: 500,
                    fade: false,
                    arrows: false,
                    cssEase: 'linear',
                    dotsClass: 'sliderh__dots',
                });    
            }
        }
    }, 300);

    //TABS HOME 
    //$('.asigurari--tab-js').easyResponsiveTabs();


    // function DropDown(el) {
    //     this.dd = el;
    //     this.placeholder = this.dd.children('span');
    //     this.opts = this.dd.find('ul.dropdown > li');
    //     this.val = '';
    //     this.index = -1;
    //     this.initEvents();
    // }
    // DropDown.prototype = {
    //     initEvents : function() {
    //         var obj = this;

    //         obj.dd.on('click', function(event){
    //             $(this).toggleClass('active');
    //             return false;
    //         });

    //         obj.opts.on('click',function(){
    //             var opt = $(this);
    //             obj.val = opt.text();
    //             obj.index = opt.index();
    //             obj.placeholder.text(obj.val);
    //         });
    //     },
    //     getValue : function() {
    //         return this.val;
    //     },
    //     getIndex : function() {
    //         return this.index;
    //     }
    // }

    // $(function() {

    //     var dd = new DropDown( $('#dd') );

    //     $(document).click(function() {
    //         // all dropdowns
    //         $('.wrapper-dropdown-3').removeClass('active');
    //     });

    // });

    //drop down menu mobile
    if($(window).width() <= 767) {
        function DropDown(el) {
            this.dd = el;
            this.placeholder = this.dd.children('span');
            this.opts = this.dd.find('ul.dropdown > li');
            this.val = '';
            this.index = -1;
            this.initEvents();
        }
        DropDown.prototype = {
            initEvents : function() {
                var obj = this;
    
                obj.dd.on('click', function(event){
                    $(this).toggleClass('active');
                    return false;
                });
    
                obj.opts.on('click',function(){
                    var opt = $(this);
                    obj.val = opt.text();
                    obj.index = opt.index();
                    obj.placeholder.text(obj.val);
                });
            },
            getValue : function() {
                return this.val;
            },
            getIndex : function() {
                return this.index;
            }
        }
    
        $(function() {
    
            var dd = new DropDown( $('#ddxs') );
    
            $(document).click(function() {
                // all dropdowns
                $('.wrapper-dropdown-xs').removeClass('active');
            });
    
        });
   }
   //end drop down menu mobile


    //tabs group home
    $('.tabs-group-js').each(function() {

        var maintabs_lists = $('li', $(this)),
            maintabs_anchors = $('a', maintabs_lists),
            maintabs, selector = '';

        maintabs_anchors.each(function(index) {

            selector += ($(this).attr('href') != '#' ? (selector !== '' ? ', ' : '') + $(this).attr('href') : '');

            $(this).on('click', function(e) {
                e.preventDefault();
                document.location.hash = $(this).attr('href');
                maintabs_lists.removeClass('current-menu-item');
                $(maintabs_lists[index]).addClass('current-menu-item');
                maintabs.css('display', 'none').removeClass('active');
                $(maintabs[index]).removeClass('hidden').css('display', 'block').addClass('active');
            });

        });

        maintabs = $(selector);

        maintabs.easyResponsiveTabs({
            type: 'vertical',
            width: 'auto',
            fit: true,
            closed: 'accordion',
            activate: function() {
                document.location.hash = '';
            }
        });

        maintabs.css('display', 'none').removeClass('active');
        $(maintabs[0]).css('display', 'block').addClass('active');

        if (document.location.hash !== '') {
            setTimeout(function() {
                var active_tab = document.location.hash.replace(/[0-9]+$/, '');
                //console.log(active_tab);
                if ($(active_tab).length > 0) {
                    maintabs_lists.removeClass('current-menu-item');
                    maintabs.css('display', 'none').removeClass('active');
                    $('[href="' + active_tab + '"]', maintabs_lists).parents('li').addClass('current-menu-item');
                    $(active_tab).removeClass('hidden').css('display', 'block').addClass('active'); 
                    $('#ddxs span').text($('[href="' + active_tab + '"]', maintabs_lists).text());
                }
            }, 250);
        }

    });


    //SLIDER NOUTATI
    if ($('.slidernoutati--js').length > 0) {
        $('.slidernoutati--js').slick({
            dots: true,
            centerMode: true,
            slidesToShow: 3,
            initialSlide: 0,
            arrows: false,
            cssEase: 'linear',
            speed: 500,
            dotsClass: 'sliderh__dots',
            centerPadding: '320px',
            
            responsive: [
                {
                breakpoint: 1400,
                    settings: {
                        centerPadding: '200px',
                    }
                },
                {
                breakpoint: 1200,
                    settings: {
                        centerPadding: '100px',
                    }
                },
                {
                breakpoint: 1000,
                    settings: {
                        centerPadding: '10px',
                    }
                },
                {
                breakpoint: 767,
                    settings: {
                        centerPadding: '10px',
                        slidesToShow: 2,
                    }
                },
                {
                breakpoint: 480,
                    settings: {
                        centerPadding: '10px',
                        slidesToShow: 1,
                    }
                }
            ]

        })
        // .on('beforeChange', function(event, slick, currentSlide, nextSlide){
        //     $('.slick-slide').removeClass('slick-prev slick-next');
        // })
        .on('afterChange', function(event, slick, currentSlide, nextSlide){
            $('.slick-active').prev().addClass('slick-prev');
            $('.slick-active').next().addClass('slick-next');
        });

        if($(window).width() >= 1001) {
            $('.slidernoutati--js .slick-active').prev().addClass('slick-prev');
            $('.slidernoutati--js .slick-active').next().addClass('slick-next');
        }
   
    }


    //ADD CLASS ON SCROLL
    var logo__shadow = $('.header__lab .logo'),
        header__bg = $('.header'); 
         //btnmenu__shadow = $('.header__lab .button-menu');
    
    if($(window).width() <= 767) {
        $(window).on('load resize scroll',function(e){
            var scroll = $(window).scrollTop();
            //console.log(scroll);
            if (scroll >= 1) {
                //console.log('a');
                logo__shadow.addClass('shadow');
                header__bg.addClass('header--bg')
                //btnmenu__shadow.addClass('shadow');
            } else {
                //console.log('a');
                logo__shadow.removeClass('shadow');
                header__bg.removeClass('header--bg')
                //btnmenu__shadow.removeClass('shadow');
            }
        });
    }

    //ACCORDION FOOTER MENU & CONTACT SECTION
    if($(window).width() <= 767) {
        var accordionxs = $('.accordion-xs-js');

        accordionxs.each(function(i) {
            var accordion_toggle = $('.accordion-toggle', this);
                accordion_content = accordion_toggle.next();
                accordion_content.hide();
            accordion_toggle.each(function(index) {
                $(this).on('click', function() {
                    var content = $(accordion_content[index]);
                    if($(this).hasClass('active')) {
                        $(this).removeClass('active');
                        content.slideUp('fast').removeClass('active');
                    } else {
                        $(this).addClass('active');
                        content.slideDown('fast').addClass('active');
                    }
                });
            });
        });


        var accordioncontactxs = $('.accordion-xs-contact-js');

        accordioncontactxs.each(function(i) {
            var accordion_togglec = $('.accordion-togglec', this);
                accordion_contentc = accordion_togglec.next();
                accordion_contentc.hide();
            accordion_togglec.each(function(index) {
                $(this).on('click', function() {
                    var contentc = $(accordion_contentc[index]);
                    if($(this).hasClass('active')) {
                        $(this).removeClass('active');
                        contentc.slideUp('fast').removeClass('active');
                    } else {
                        $(this).addClass('active');
                        contentc.slideDown('fast').addClass('active');
                    }
                });
            });
        });

    }

    //ACCORDION CONTACT
    // if($(window).width() <= 767) {
    //     var accordionxs = $('.accordion-xs-js');

    //     accordionxs.each(function(i) {
    //         var accordion_toggle = $('.item-footer h5', this);
    //             accordion_content = accordion_toggle.next();
    //             accordion_content.hide();
    //         accordion_toggle.each(function(index) {
    //             $(this).on('click', function() {
    //                 var content = $(accordion_content[index]);
    //                 if($(this).hasClass('active')) {
    //                     $(this).removeClass('active');
    //                     content.slideUp('fast').removeClass('active');
    //                 } else {
    //                     $(this).addClass('active');
    //                     content.slideDown('fast').addClass('active');
    //                 }
    //             });
    //         });
    //     });        
    // }


}

jQuery(function() {

    var app = new App();
    app.init();

});
